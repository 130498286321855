import { Swiper } from 'swiper/react'
import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

import { LinkLabel as Link, LinkWrapper } from '@/components/Link/style'

export const WinesSwiper = styled(Swiper)`
    width: 100%;
    display: flex;
    height: fit-content;
    max-height: 80rem;
    position: relative;
    z-index: 10;
`

export const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  z-index: 10;
  ${mediaMax.xs}{
    position: sticky;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`

export const Action = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 0;
  width: 100%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity .5s ease;
  pointer-events: none;
  
  ${LinkWrapper}{
    background: ${({ theme }) => theme.colors.lightVanilla3};
  }

  ${Link}{
    margin: 2rem;
    color: ${({ theme }) => theme.colors.lightGreen3};
    opacity: 0;

    &:after{
      height: 1px;
      background-color: ${({ theme }) => theme.colors.lightGreen3};
    }
  }

  &.active {
    opacity: 1;
    transition: opacity 1s .5s ease;
    pointer-events: all;
    ${Link}{
      opacity: 1;
    }
  }
`
