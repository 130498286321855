import styled from '@emotion/styled'
import { mediaMax, mediaMin } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.lightVanilla3};
  --translateX: ${({ percentX }) => `${percentX}%`};
  --translateY: ${({ percentY }) => `${percentY}%`};
`

export const NavContainer = styled.div`
  position: absolute;
  width: 10%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  ${mediaMax.sm}{
    top: 13rem;
    width: fit-content;
  }
`

export const ItemsContainer = styled.div`
  width: 100%;
  height: 100%;
`
