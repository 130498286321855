import styled from '@emotion/styled'
import { mediaMax, mediaMin } from '@/styles/mixins'
import { Swiper } from 'swiper/react'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const List = styled(Swiper)`
  width: 100%;
  flex-direction: row;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 2.5vw;
  background-color: ${p => p.theme.colors.lightVanilla3};
  width: 100vw;

`

export const Item = styled.a`
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  text-transform: uppercase;
  color: ${({ theme, active }) => active ? "#748451" : theme.colors.lightGrey2};
  font-size: 1.25rem;
  padding-top: 1.2rem;
  padding-bottom: 1rem;
  cursor: pointer;
  transition: all ease-out .4s;
  margin: auto;
  display: block;
  width: fit-content;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 340px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 310px) {
    font-size: 1rem;
  }
  

  &:before {
    content: '';
    position: absolute;
    right: calc(100% + 14px);
    bottom: -.6rem;
    left: 35%;
    transform: translateY(-50%) ${({ active }) => active ? 'scaleX(1)' : 'scaleX(0)'};
    transform-origin: left center;
    background-color: #748451;
    width: 30%;

    height: 1px;
    transition: transform ease-out .4s;
  }
`

export const AdditionalItem = styled(Item)`
  text-decoration: underline;
  ${mediaMax.sm}{
    display: none;
  }

  &:before {
    display: none;
  }
`
