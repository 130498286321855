import * as React from 'react'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import PropTypes from 'prop-types'
import cn from 'classnames'

import resolveAssetURL from '@/utils/resolveAssetURL'

import { useUpdateEffect } from '@/hook'
import { wineType } from '@/model/wine'
import { isTouchDeviceState } from '@/recoil/device'

import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'

import {
  Container,
  BgContainer,
  NameLabel,
  Wrapper,
  ArtContainer,
  ColorCircle,
  ArtCircle,
  ImageBaseWrapper,
  ImageRotationWrapper,
  ImageParallaxWrapper,
  Image,
  TextContainer,
  Label,
  Title,
  Text,
  Actions,
  Action
} from './style'

import Button from '@/components/Button'

const Item = ({ item, index, activeIndex, onClick, percentX, percentY, mounted, isMoving }) => {
  const [isHover, setIsHover] = useState(false)
  const [isCurr, setIsCurr] = useState(index === activeIndex)
  const [isNext, setIsNext] = useState(index === activeIndex + 1)
  const [isPrev, setIsPrev] = useState(index === activeIndex - 1)
  const [isBefore, setIsBefore] = useState(index < activeIndex)
  const [isAfter, setIsAfter] = useState(index > activeIndex)
  const isTouch = useRecoilValue(isTouchDeviceState)

  const handleMouseEnter = () => { setIsHover(true) }
  const handleMouseLeave = () => { setIsHover(false) }
  const handleWineClick = () => {
    onClick(index + 1)
  }

  useUpdateEffect(() => {
    setIsCurr(index === activeIndex)
    setIsNext(index === activeIndex + 1)
    setIsPrev(index === activeIndex - 1)
    setIsBefore(index < activeIndex)
    setIsAfter(index > activeIndex)
  }, [activeIndex, index, onClick])

  return (
    <Container
      percentX={ percentX }
      percentY={ percentY }
      className={ cn({ isNext, isPrev, isCurr, isBefore, isAfter, mounted }, { isHover: isHover && !isMoving }) }
      onClick={ handleWineClick }
    >
      <Wrapper>
        <ArtContainer>
          <ColorCircle color={ item.colorBg } />
          <ArtCircle
            color={ item.colorBg }
            image={ resolveAssetURL(item.art) }
            isMoving={ isMoving }
          />
        </ArtContainer>
      </Wrapper>
      <BgContainer>
        <NameLabel>
          { item.name }
        </NameLabel>
      </BgContainer>
      <Wrapper>
        <ArtContainer>
          <ImageBaseWrapper>
            <ImageRotationWrapper>
              <ImageParallaxWrapper>
                {isTouch && (
                  <Image
                    loading='lazy'
                    src={ resolveAssetURL(item.img) }
                    alt={ item.title }
                    onMouseEnter={ (e) => handleMouseEnter(e) }
                    onMouseLeave={ (e) => handleMouseLeave(e) }
                  />)}
                {!isTouch && (
                  <LocalizedGatsbyLink to={ item.path }>
                    <Image
                      loading='lazy'
                      src={ resolveAssetURL(item.img) }
                      alt={ item.title }
                      onMouseEnter={ (e) => handleMouseEnter(e) }
                      onMouseLeave={ (e) => handleMouseLeave(e) }
                    />
                  </LocalizedGatsbyLink>)}
              </ImageParallaxWrapper>
            </ImageRotationWrapper>
          </ImageBaseWrapper>
        </ArtContainer>
        <TextContainer>
          <Label
            { ...(index === 0) && ({ as: 'h1' }) }
            { ...(index > 0) && ({ as: 'p' }) }
          >
            Our Wines
          </Label>
          <Title>{ item.title }</Title>
          <Text>{ item.text }</Text>
          <Actions>
            <Action
              onMouseEnter={ (e) => handleMouseEnter(e) }
              onMouseLeave={ (e) => handleMouseLeave(e) }
            >
              <Button
                onClick={ (e) => handleWineClick(e) }
                type='custom'
                color={ item.colorCta }
                to={ item.path }
              >
                { item.cta }
              </Button>
            </Action>
            {/* <Action>
              <Link onClick={ (e) => handleFinderClick(e) }>
                Wine finder
              </Link>
            </Action> */}
          </Actions>
        </TextContainer>
      </Wrapper>
    </Container>
  )
}

Item.propTypes = {
  item: wineType.isRequired,
  index: PropTypes.number,
  activeIndex: PropTypes.number,
  percentX: PropTypes.number,
  percentY: PropTypes.number
}

export default Item
