import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightVanilla3};
  /* min-height: 100vh; */
  width: 100vw;
  /* overflow: hidden; */
  margin-top: ${({ theme }) => theme.header.normal}px;
`

export const NavContainer = styled.div`
  position: absolute;
  width: 10%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  ${mediaMax.sm}{
    position: static;
    width: fit-content;
    transform: none;
  }
`
