import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { wineType } from '@/model/wine'
import { useMountEffect, useUpdateEffect } from '@/hook'

import Nav from './Nav'
import Item from './Item'

import {
  Container,
  NavContainer,
  ItemsContainer
} from './style'

const __SCROLL_LIMIT__ = 50

const WinesVerticalSlider = ({ items = [], otherWinesLabel }) => {
  const [isMoving, setIsMoving] = useState(false)
  const [scrollProgress, setScrollProgress] = useState(0)
  const [percent, setPercent] = useState({ x: 0, y: 0 })
  const [activeIndex, setActiveIndex] = useState(0)
  const [firstMount, setFirstMount] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setFirstMount(true)
    }, 1000)
  }, [])

  const onWheel = async (e) => {
    const { deltaY } = e
    setScrollProgress(deltaY)
    e.preventDefault()
  }

  const goTo = (i) => {
    let index = i

    if (i < 0) {
      index = 0
    }

    if (i >= (items.length - 1)) {
      index = (items.length - 1)
    }

    if (!isMoving) {
      setActiveIndex(index)
      setIsMoving(true)
      setScrollProgress(0)
      setPercent({ x: 0, y: 0 })

      setTimeout(() => {
        setIsMoving(false)
      }, 1500)
    }
  }

  useMountEffect(() => {
    const factor = 1

    let mouseX,
      mouseY

    window.addEventListener('mousemove', (e) => {
      mouseX = e.pageX
      mouseY = e.pageY

      if (!isMoving) {
        setPercent({
          x: ((mouseX - (window.innerWidth * 0.5)) * factor) / 100,
          y: ((mouseY - (window.innerHeight * 0.5)) * factor) / 100
        })
      }
    })

    window.addEventListener('wheel', onWheel, { passive: false })
  })

  useUpdateEffect(() => {
    if (scrollProgress >= __SCROLL_LIMIT__) {
      goTo(activeIndex + 1)
    } else if (scrollProgress <= -__SCROLL_LIMIT__) {
      goTo(activeIndex - 1)
    }
  }, [scrollProgress])

  const onItemClick = (i) => {
    goTo(i)
  }

  useEffect(() => {
    return () => {
      window.removeEventListener('wheel', onWheel)
    }
  }, [])

  return (
    <Container
      percentX={ percent.x }
      percentY={ percent.y }
    >
      <NavContainer>
        <Nav
          otherWinesLabel={ otherWinesLabel }
          onItemClick={ onItemClick }
          items={ items }
          activeIndex={ activeIndex }
        />
      </NavContainer>
      <ItemsContainer>
        {
          items.map((item, i) => (
            <Item
              mounted={ firstMount }
              key={ i }
              item={ item }
              index={ i }
              activeIndex={ activeIndex }
              itemsLength={ items.length }
              onClick={ onItemClick }
              isMoving={ isMoving }
            />
          ))
        }
      </ItemsContainer>
    </Container>
  )
}

WinesVerticalSlider.propTypes = {
  otherWinesLabel: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    wineType
  ).isRequired
}

export default WinesVerticalSlider
