import cn from 'classnames'

import { CirclesContainer, Circles, ArtCircle, ColorCircle } from './style'

export default function Circle({ items, activeIndex }) {
  return (
    <CirclesContainer>
    {
      items.map((item, i) => (
      <Circles className={cn({active: activeIndex === i})} key={item.name}>
        {(item.colorBg || item.imageBg) && <ColorCircle color={ item.colorBg } image={ item.imageBg } />}
        {item.art && <ArtCircle image={ item.art } />}
      </Circles> 
      ))
    }
    </CirclesContainer>
  )
}
