import { SwiperSlide } from 'swiper/react'
import { Controller } from 'swiper'
import PropTypes from 'prop-types'
import { wineType } from '@/model/wine'

import {
  Container,
  List,
  Item
} from './style'

const Nav = ({ items, setSwiper, controller, swiper }) => {
  return (
    <Container>
      <List
        onSwiper={ setSwiper }
        centeredSlides
        spaceBetween={ 0 }
        speed={ 1000 }
        slidesPerView={ 2.5 }
        controller={ { control: controller } }
        modules={ [Controller] }
        allowTouchMove={ false }
      >
        {
          items.map((item, index) =>
            (
              <SwiperSlide key={ index }>
                {({ isActive }) => (
                  <Item
                    onClick={ () => {
                      swiper.slideTo(index)
                    } }
                    active={ isActive }
                    key={ index }
                  >
                    { item.name }
                  </Item>
                )}
              </SwiperSlide>
            )
          )
        }
      </List>
    </Container>
  )
}

Nav.propTypes = {
  controller: PropTypes.object,
  swiper: PropTypes.object,
  setSwiper: PropTypes.func,
  items: PropTypes.arrayOf(
    wineType
  ).isRequired
}

export default Nav
