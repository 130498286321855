import * as React from 'react'

import resolveAssetURL from '@/utils/resolveAssetURL'

import PropTypes from 'prop-types'
import { wineType } from '@/model/wine'

import cn from 'classnames'

import {
  Container,
  BgContainer,
  NameLabel,
  Wrapper,
  ArtContainer,
  ImageBaseWrapper,
  ImageRotationWrapper,
  ImageParallaxWrapper,
  Image,
  TextContainer,
  Label,
  Title,
  Text
} from './style'

const Wine = ({ item, isActive, isPrev, isNext, isLast, index }) => {
  return (
    <Container
      className={ cn({ isNext, isPrev, isCurr: isActive, isLast: isLast }, 'mounted') }
    >
      <BgContainer>
        <NameLabel>
          { item.name }
        </NameLabel>
      </BgContainer>
      <Wrapper>
        <ArtContainer>
          <ImageBaseWrapper>
            <ImageRotationWrapper>
              <ImageParallaxWrapper>
                {item.img && <Image loading='lazy' src={ resolveAssetURL(item.img) } alt={ item.title } />}
              </ImageParallaxWrapper>
            </ImageRotationWrapper>
          </ImageBaseWrapper>
        </ArtContainer>
        <TextContainer>
          <Label
            { ...(index === 0) && ({ as: 'h1' }) }
            { ...(index > 0) && ({ as: 'p' }) }
          >Our Wines
          </Label>
          <Title>{ item.title }</Title>
          <Text>{ item.text }</Text>
        </TextContainer>
      </Wrapper>
    </Container>
  )
}

Wine.propTypes = {
  item: wineType.isRequired,
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  isNext: PropTypes.bool
}

export default Wine
