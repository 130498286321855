import * as React from 'react'

import { SwiperSlide } from 'swiper/react'
import { Controller } from 'swiper'
import { useState } from 'react'
import cn from 'classnames'

import Wine from '../Wine'
import Circles from '../Circles'
import Link from '@/components/Link'
import Button from '@/components/Button'

import theme from '@/theme'

import {
  WinesSwiper,
  SliderContainer,
  Actions,
  Action
} from './style'

const Slider = ({ items = [], setSwiper, controller, duration, setIsMoving, isMoving }) => {
  const [clientX, setClientX] = useState(null)
  const [clientY, setClientY] = useState(null)
  const [activeItem, setActiveItem] = useState(0)
  return (
    <SliderContainer>
      <WinesSwiper
        key='mobile'
        onSwiper={ setSwiper }
        forceToAxis
        controller={ { control: controller } }
        modules={ [Controller] }
        speed={ duration }
        allowTouchMove={ false }
        onSlideChange={ (swiper) => {
          setActiveItem(swiper.activeIndex)
        } }
        onTouchStart={ (s, e) => {
          setClientX(e.touches[0].clientX)
          setClientY(e.touches[0].clientY)
        } }
        onTouchEnd={ (s, e) => {
          if (!clientX || isMoving) return
          const minX = 3
          const diffX = clientX - e.changedTouches[0].clientX
          const diffY = clientY - e.changedTouches[0].clientY
          const isVerticalSlide = Math.abs(diffY) > Math.abs(diffX)
          const shouldSlide = !isVerticalSlide && Math.abs(diffX) > minX
          if (!shouldSlide) return
          setIsMoving(true)
          if (diffX > 0) {
            s.slideNext()
          } else if (diffX < 0) {
            s.slidePrev()
          }
          setTimeout(() => {
            setIsMoving(false)
          }, duration)
          setClientY(null)
          setClientX(null)
        } }
      >
        {
                items.map((item, i) => (
                  <SwiperSlide key={ i }>
                    {({ isActive, isPrev, isNext }) => (
                      <Wine
                        item={ item }
                        isActive={ isActive }
                        isPrev={ isPrev }
                        isNext={ isNext }
                        isLast={ i === items.length - 1 }
                        scroll={ isActive ? scroll : 0 }
                        index={ i }
                      />
                    )}
                  </SwiperSlide>
                ))
              }
      </WinesSwiper>
      <Circles items={ items } activeIndex={ activeItem } />
      <Actions>
        {
            items.map((item, i) => (
              <Action key={ i } className={ cn({ active: i === activeItem }) }>
                {activeItem === items.length - 1
                  ? 
                  (
                    // <Link onClick={ () => {} }>Find your soulmate</Link>
                    <Button
                      onClick={ () => {} }
                      type='custom'
                      color={ theme.colors.lightGreen3 }
                      to={ item.path }
                    >
                      Discover
                    </Button>
                  )
                  : (
                    <Button
                      onClick={ () => {} }
                      type='custom'
                      color={ theme.colors.lightGreen3 }
                      to={ item.path }
                    >
                      Discover
                    </Button>
                    )}
              </Action>
            )
            )
          }
      </Actions>
    </SliderContainer>
  )
}

export default Slider
