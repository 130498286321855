import styled from '@emotion/styled'
import { mediaMax, mediaMin } from '@/styles/mixins'
import { keyframes } from '@emotion/react'

const opacityEnterAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 2.5vw;
  letter-spacing: 0.1rem;
  ${mediaMax.sm}{
    flex-direction: row;
    background-color: ${p => p.theme.colors.lightVanilla3};
    width: 100vw;
  }
`

export const Item = styled.div`
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  text-transform: uppercase;
  color: ${({ theme, active }) => active ? '#748451' : theme.colors.lightGrey2};
  font-size: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  transition: all ease-out .4s;
  opacity: 0;
  animation: ${opacityEnterAnimation} .4s forwards;
  animation-delay: ${({ index }) => index * 0.1}s;
  ${mediaMax.sm}{
    margin: auto;
    display: block;
    width: fit-content;
    font-size: 1.4rem;
  }
  ${mediaMin.sm}{
    &:hover {
      color: #748451;
      padding-left: 5px;
    }
  }


  &:before {
    content: '';
    position: absolute;
    right: calc(100% + 14px);
    top: 50%;
    transform: translateY(-50%) ${({ active }) => active ? 'scaleX(1)' : 'scaleX(0)'};
    transform-origin: left center;
    background-color: #748451;
    width: calc(2.5vw - 14px);
    height: 1px;
    transition: transform ease-out .4s;
  }
`

export const AdditionalItem = styled(Item)`
  text-decoration: underline;
  ${mediaMax.sm}{
    display: none;
  }

  &:before {
    display: none;
  }

  a {
    color: inherit;
  }
`
