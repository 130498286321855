import styled from '@emotion/styled'

const BaseCircle = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 40vh;
  height: 40vh;
  border-radius: 50%;
  background-image: url(${({ image }) => image});
`

export const ColorCircle = styled(BaseCircle)`
  background-color: ${({ color }) => color};
  transition: opacity ease .5s;
  opacity: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

export const ArtCircle = styled(BaseCircle)`
  background-position: 50% 50%;
  background-size: 95%;
  background-repeat: no-repeat;
  transition: clip-path ease .5s;
  clip-path: circle(0.0% at 50% 50%);
`

export const Circles = styled.div`
  &.active{
    opacity: 1;
    ${ArtCircle}{
      transition: clip-path ease 1s 1s;
      clip-path: circle(100% at 50% 50%);
     
    }
    ${ColorCircle}{
      transition: opacity ease .5s;
      opacity: 1;
    }
  }
`
export const CirclesContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: fit-content;
  background: red;
  top: 80px;
  z-index: 9;
`