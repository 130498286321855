import * as React from 'react'
import { graphql } from 'gatsby'
import { useRecoilValue } from 'recoil'
import { useLocation } from '@reach/router'
import Layout from '@/layouts/Default'
import WinesVerticalSlider from '@/components/WinesVerticalSlider'
import WinesHorizontalSlider from '@/components/WinesHorizontalSlider'
import StoreLocator from '@/components/StoreLocator'
import { isMobileState } from '@/recoil/layout'
import { Helmet } from 'react-helmet'
import { marketCodeManager } from '@/recoil/marketCode'

const OurWinesPage = (props) => {
  const data = props.data.drupal.taxonomyTermQuery.entities
  const pageMarketCode = props.pageContext.marketCode
  const isMobile = useRecoilValue(isMobileState)
  const marketCode = useRecoilValue(marketCodeManager)

  const location = useLocation()

  const ourWineData = props?.data?.drupal?.ourWineQuery?.fieldMegamenuItems.find((item) => {
    let markets = []
    const { entity } = item

    if (entity.fieldMenuOurWinesMarkets) {
      markets = entity.fieldMenuOurWinesMarkets.map(market => `${market.entity.langcode.value}-${market.entity.fieldCountryCode}`.toLowerCase())
    }

    return markets.includes(marketCode)
  })

  const items = data
    .map((category) => (
      {
        img: category.fieldWineCatPrimaryVintage.entity.fieldWineProductPackshot.url,
        art: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackillustrat.url,
        name: category.fieldWineProductCategoryName,
        colorBg: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackcolor,
        colorCta: category.fieldWineCatPrimaryVintage.entity.fieldProductDiscoverColorCta,
        title: category.fieldWineCatPrimaryVintage.entity.fieldCategoryProductName,
        text: category.fieldWproductCatDescription,
        path: category.fieldWineCatPrimaryVintage.entity.path.alias,
        cta: category.fieldWineCatPrimaryVintage.entity.fieldWineProductDiscoverCta?.title || ''
      }
    ))

  return (
    <Layout
      path={ props.pageContext?.pagePath }
      smallFooter
      footer
      plainHeader
      noPadding
      pageType='Product List Page'
      pageMarketCode={ pageMarketCode }
    >
      <Helmet>
        <title>Our wines | Marlborough - Central Otago | Cloudy Bay</title>
        <meta name='description' content='Cloudy Bay’s Marlborough Sauvignon Blanc was just the start. Discover our full range of Marlborough wines and our premium Central Otago Pinot Noir.' />
        <meta property='og:title' content='Our wines | Marlborough - Central Otago | Cloudy Bay' />
        <meta property='og:description' content='Cloudy Bay’s Marlborough Sauvignon Blanc was just the start. Discover our full range of Marlborough wines and our premium Central Otago Pinot Noir.' />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${props.pageContext?.pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='website' />
        <meta property='og:image' content={ `${location.origin}/images/cloudy-bay-our-wines-og-image.jpg` } />
      </Helmet>
      {isMobile
        ? (
          <WinesHorizontalSlider
            items={ items }
          />
        )
        : (
          <WinesVerticalSlider
            items={ items }
            otherWinesLabel={ ourWineData?.entity?.fieldMenuOurWinesOtherWines?.title || 'SPECIAL RELEASE WINES' }
          />
        )}
    </Layout>
  )
}

export const query = graphql`
  query ProductsQuery {
    drupal {
      ourWineQuery: nodeById(id: "296") {
        ... on Drupal_NodeMegamenu {
          nid
          fieldMegamenuItems {
            entity {
              id
              ... on Drupal_ParagraphMenuItemOurWines {
                id
                fieldMenuOurWinesMarkets {
                  entity {
                    ... on Drupal_TaxonomyTermCountry {
                      langcode {
                        value
                      }
                      fieldCountryCode
                    }
                  }
                }
                fieldMenuOurWinesOtherWines {
                  url {
                    path
                  }
                  title
                }
                fieldMenuItemOurWinesIntro
                fieldMenuItemOurWinesTitle
                fieldMenuItemOurWinesCta {
                  title
                  url {
                    path
                  }
                }
              }
            }
          }
        }
      }
      taxonomyTermQuery(limit: 1000, offset: 0, sort: [{field: "weight", direction: ASC}], filter: {conditions: [{operator: EQUAL, field: "vid", value: ["wine_category"]}]}) {
        entities {
          ... on Drupal_TaxonomyTermWineCategory {
            name
            fieldWineProductCategoryName
            fieldWproductCatDescription
            fieldWineCatPrimaryVintage {
              entity {
                ...on Drupal_NodeWineProduct {
                  nid
                  path {
                    alias
                  }
                  title
                  fieldProductDiscoverColorCta
                  fieldWineProductBackcolor
                  fieldWineProductBackillustrat {
                    url
                  }
                  fieldWineProductShortDesc
                  fieldWineProductName
                  fieldCategoryProductName
                  fieldWineProductDiscoverCta {
                    title
                  }
                  fieldWineProductPackshot {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default OurWinesPage
