import { useState } from 'react'
import { useRecoilValue } from 'recoil'

import PropTypes from 'prop-types'
import { wineType } from '@/model/wine'
import { headerHeightState } from '@/recoil/header'

import wineFinderImage from '@/images/subnav-wine-finder.png'

import Nav from './Nav'
import Slider from './Slider/'

import {
  Container,
  NavContainer
} from './style'

const ANIMATION_DURATION = 1000

const WinesHorizontalSlider = ({ items = [] }) => {
  const [navSwiper, setNavSwiper] = useState(null)
  const [winesSwiper, setWinesSwiper] = useState(null)
  const [isMoving, setIsMoving] = useState(false)
  const headerHeight = useRecoilValue(headerHeightState)
  const lastItem = {
    name: 'Find your soul mate',
    colorBg: 'transparent',
    imageBg: wineFinderImage,
    title: 'Wine finder',
    text: '',
    img: '',
    colorCta: '',
    art: ''
  }

  return (
    <Container>
      <NavContainer>
        <Nav
          items={ [...items
            // lastItem
          ] }
          speed={ ANIMATION_DURATION }
          setSwiper={ setNavSwiper }
          swiper={ navSwiper }
          controller={ winesSwiper }
        />
      </NavContainer>
      <Slider items={ [...items] } isMoving={ isMoving } setIsMoving={ setIsMoving } duration={ ANIMATION_DURATION } setSwiper={ setWinesSwiper } controller={ navSwiper } />
    </Container>
  )
}

WinesHorizontalSlider.propTypes = {
  items: PropTypes.arrayOf(
    wineType
  ).isRequired
}

export default WinesHorizontalSlider
